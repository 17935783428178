import React from 'react'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'
import Slider from '../../../shared_components/Slider/Slider'

export default function Section2() {
    let data = [
        {
            'pic_path':"./assets/ard.png",
            'seo':"ahmed mohsen",
            'title':"تقنيات تتبع ارديونو",
            'desc':"تم تنفيده من قبل طالبنا المتميز عمر الزواري",
        },
        {
            'pic_path':"./assets/ard.png",
            'seo':"ahmed mohsen",
            'title':"تقنيات تتبع ارديونو",
            'desc':"تم تنفيده من قبل طالبنا المتميز عمر الزواري",
        },
    ]
  return (
    <div className=' bg-[#18AAF2] w-full'>
        <svg className='w-full h-auto' viewBox="0 0 1913 263" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-7 210.491L39 188.575C84 166.658 176 122.825 267 100.908C359 78.9913 450 78.9913 542 96.342C633 114.149 724 148.851 816 153.417C907 157.983 999 131.5 1090 140.175C1182 148.851 1273 192.684 1364 179.899C1456 166.658 1547 96.342 1639 100.908C1730 105.017 1822 184.009 1867 223.733L1913 263V0H1867C1822 0 1730 0 1639 0C1547 0 1456 0 1364 0C1273 0 1182 0 1090 0C999 0 907 0 816 0C724 0 633 0 542 0C450 0 359 0 267 0C176 0 84 0 39 0H-7V210.491Z" fill="#F7F8F9"/>
</svg>

        <div className='page-layout gap-16'>
        <MainTitle label="من بين اطفالنا المبدعين" color='white'/>
        <Slider data={data} />
        </div>

    </div>
  )
}
