import React, { useState } from 'react';
import axios from 'axios';
import { Utils } from '../../../lib/Custom-Utils';

const AdminLogin = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${Utils.getUrlApi()}admin/login`, { login, password });
      if (response.status === 200 && response.data.token) {
        localStorage.setItem('token', response.data.token);
        window.open('./panel', '_self');
      }
    } catch (error) {
      setError('Invalid login or password');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-black">
      <a href="/" className="flex items-center mb-6 text-2xl font-semibold text-white">
        <img className="w-[155px] mr-2" src="/assets/logos/logo.png" alt="logo" />
      </a>
      <div className="w-full max-w-7xl rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl font-int text-white">
            Login to Admin System
          </h1>
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
            <div>
              <label className="block mb-2 text-sm font-medium text-white">Username</label>
              <input
                type="text"
                name="login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                className="border  sm:text-sm rounded-lg focus:ring-xprimary-600 focus:border-xprimary-600 block w-full p-2.5 border-gray-600 placeholder-gray-400 text-dark focus:ring-blue-500 focus:border-blue-500"
                placeholder="Username"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-white">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border sm:text-sm rounded-lg focus:ring-xprimary-600 focus:border-xprimary-600 block w-full p-2.5 border-gray-600 placeholder-gray-400 text-dark focus:ring-blue-500 focus:border-blue-500"
                placeholder="••••••••"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full text-white bg-primary hover:bg-xprimary-700 focus:ring-4 focus:outline-none focus:ring-xprimary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-xprimary-600 hover:bg-xprimary-700 focus:ring-xprimary-800"
            >
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
