import { useState } from "react";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";

export function Register() {
  // Define state for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  // Define state for errors
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
  });

  // Validate form inputs
  const validate = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!firstName.trim()) {
      newErrors.firstName = "الاسم الأول مطلوب";
      valid = false;
    } else {
      newErrors.firstName = "";
    }

    if (!lastName.trim()) {
      newErrors.lastName = "الاسم الأخير مطلوب";
      valid = false;
    } else {
      newErrors.lastName = "";
    }

    if (!email.trim()) {
      newErrors.email = "البريد الإلكتروني مطلوب";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "البريد الإلكتروني غير صالح";
      valid = false;
    } else {
      newErrors.email = "";
    }

    if (!password.trim()) {
      newErrors.password = "كلمة المرور مطلوبة";
      valid = false;
    } else if (password.length < 6) {
      newErrors.password = "كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل";
      valid = false;
    } else {
      newErrors.password = "";
    }

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "رقم الهاتف مطلوب";
      valid = false;
    } else if (!/^\d{8,15}$/.test(phoneNumber)) {
      newErrors.phoneNumber = "رقم الهاتف غير صالح";
      valid = false;
    } else {
      newErrors.phoneNumber = "";
    }

    if (!address.trim()) {
      newErrors.address = "العنوان مطلوب";
      valid = false;
    } else {
      newErrors.address = "";
    }

    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      try {
        // Call API to submit data
        const response = await fetch("/api/parents/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            phone_number: phoneNumber,
            address,
          }),
        });
        const result = await response.json();
        if (response.ok) {
          // Handle success (e.g., redirect or show a success message)
          alert("تم إنشاء الحساب بنجاح");
        } else {
          alert(`Error: ${result.message}`);
        }
      } catch (error) {
        alert("فشل إرسال البيانات، حاول مرة أخرى لاحقاً");
      }
    }
  };

  return (
    <Card className="lg:mt-40 mt-10 mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-xl">إنشاء حساب ولي أمر</CardTitle>
        <CardDescription>أدخل معلوماتك لإنشاء حساب ولي أمر</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first-name">الاسم الأول</Label>
                <Input
                  id="first-name"
                  placeholder="احمد"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">{errors.firstName}</p>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last-name">الاسم الأخير</Label>
                <Input
                  id="last-name"
                  placeholder="علي"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email">البريد الإلكتروني</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="phone-number">رقم الهاتف</Label>
              <Input
                id="phone-number"
                type="tel"
                placeholder="12345678"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
              )}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="address">العنوان</Label>
              <Input
                id="address"
                placeholder="العنوان الكامل"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              {errors.address && (
                <p className="text-red-500 text-sm">{errors.address}</p>
              )}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password">كلمة المرور</Label>
              <Input
                id="password"
                type="password"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>
            <Button type="submit" className="w-full">
              إنشاء حساب
            </Button>
          </div>
          <div className="mt-4 text-center text-sm">
            هل لديك حساب بالفعل؟{" "}
            <a href="/login" className="underline">
              تسجيل الدخول
            </a>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}
