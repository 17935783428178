import React from 'react'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'
import { motion } from "framer-motion"
import { AiFillMessage } from 'react-icons/ai'

const item = (label: any) => {
    return (
  
        <motion.div
        className="box"
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
            <div className='w-52 h-52 bg-C2/70 border-C2 border-4 rounded-full flex justify-center items-center'>
                <AiFillMessage className='text-8xl text-white' />
            </div>
            <h1 className='text-center font-pop font-medium mt-2 text-xl text-C4'>{label}</h1>
            </motion.div>
    )
}



export default function Section3() {
    return (
        <div className='page-layout gap-4'>
            <MainTitle label="المهارات التي سيكتسبها طفلك " />
            <p className='main-paragraph lg:w-1/2 text-center'>
                كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية
            </p>
            <div className='flex justify-around w-full mt-8 flex-wrap'>
                {item("التمكن من الاندماج مع الاطفال")}
                {item("التمكن من الاندماج مع الاطفال")}
                {item("التمكن من الاندماج مع الاطفال")}
            </div>
        </div>


    )
}
