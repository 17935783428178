import React from "react";
//import { Transition } from "@headlessui/react";
// import {useLocation} from 'react-router-dom';
import 'lazysizes';
import Footer from '../../shared_components/Footer/Footer';
import InfoBar from "./components/InfoBar";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import ParallaxText from "../../shared_components/ParallaxText/ParallaxText";
import Section8 from "./components/Section8";
import Hero from "./components/Hero";
 

export default function Home() {
  //const [isOpen, setIsOpen] = useState(false);
  //const location = useLocation();

  return (
    <>
      
        
        <InfoBar />
        <Hero/>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Footer/>
    </>
  )
}
