/**
 * eslint-disable @next/next/no-img-element
 *
 * @format
 */

/** @format */
"use client";

import { DataTable } from "../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import Layout from "../../layout";
import axios from 'axios';
import { Utils } from "../../../../lib/Custom-Utils";
import { Parent } from "@/lib/types";

type Props = {};

 

const columns: ColumnDef<Parent>[] = [
  {
    accessorKey: "full_name",
    header: "Nom",
    cell: ({ row }) => {
      const { parent_id, first_name, last_name } = row.original;
      return (
        <a href={`./parents/${parent_id}`} className="flex gap-2 items-center">
          <img
            className="h-10 w-10"
            src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${first_name} ${last_name}`}
            alt="user-image"
          />
          <p>{`${first_name} ${last_name}`}</p>
        </a>
      );
    }
  },
  {
    accessorKey: "email",
    header: "Email"
  },
  {
    accessorKey: "phone_number",
    header: "Numéro de téléphone"
  },
  {
    accessorKey: "address",
    header: "Address"
  },
  {
    accessorKey: "opinion_goal",
    header: "Objectif de l'opinion"
  }
];


export default function ParentsPage() {
  const [data, setData] = useState<Parent[]>([]); // Initialize with sample data
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        // Uncomment the following lines when your API is ready
         const response = await axios.get(Utils.getUrlApi()+'parents', {
          headers: {
              'Authorization': `Bearer ${token}` // Add Bearer token to the request
          }});
         setData(response.data);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex justify-between">
          <PageTitle title="Parents" />
          <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/parents/create', '_self') }}>
          Creer un nouveau
        </button>
        </div>
        <DataTable columns={columns} data={data} />
      </div>
    </Layout>
  );
}
