import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Layout from "../../../layout";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/ui/card";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import { Member, MembershipStatus, MembershipType, Parent } from "@/lib/types";

 
 

export default function ParentById() {
    const [parent, setParent] = useState<Parent | null>(null);
    const [members, setMembers] = useState<Member[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const token = localStorage.getItem('token');

    // Using URLSearchParams to get the ID from query params
    const { id } = useParams();
    const handleDelete = async() =>{
        const PIN = prompt("PIN :");
        if(PIN==="3002"){
          await axios.delete(Utils.getUrlApi()+'parents/'+id, {
            headers: {
                'Authorization': `Bearer ${token}` // Add Bearer token to the request
            }});
          window.open('/admin/parents', '_self');
        }
      }
    useEffect(() => {
        if (!id) return; // No ID provided, exit early

        const fetchParent = async () => {
            try {
                // Commented out API call for parent
                 const parentResponse = await axios.get(`${Utils.getUrlApi()}parents/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Bearer token to the request
                    }});
                 setParent(parentResponse.data);

                // Simulate fetching parent data
                //const foundParent = sampleParentData.find(p => p.parent_id === Number(id));
               
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }

            try {
                const membersResponse = await axios.get(`${Utils.getUrlApi()}members/by-parent/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Bearer token to the request
                    }});
                setMembers(membersResponse.data);
            }catch (err) {
                //setError('Failed to fetch data');
            }
            
        };

        fetchParent();
    }, [id]);

    if (loading) return <p className="text-center">Loading...</p>;
    if (error) return <p className="text-center text-red-500">{error}</p>;
    if (!parent) return <p className="text-center">No data found</p>;
    return (
        <Layout>
            <div className="flex flex-col gap-5 p-5 w-full max-w-4xl mx-auto">
                <Card>
                    <CardHeader className="flex justify-between">
                        <div>
                            <CardTitle className="text-xl font-semibold">
                                {`${parent.first_name} ${parent.last_name}`}
                            </CardTitle>
                            <h3 className="text-lg text-gray-600">
                                Parent Details
                            </h3>
                        </div>
                        <div>
                            <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/parents/edit/' + parent.parent_id, '_self') }}>
                                Modifier
                            </button>
                            <button onClick={handleDelete} className="bg-deep-orange-800 text-white px-2 py-1"  >
                                Supprimer
                            </button>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="flex gap-4 items-center">
                            <img
                                className="h-24 w-24 rounded-full object-cover"
                                src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${parent.first_name} ${parent.last_name}`}
                                alt="user-image"
                            />
                            <div className="flex flex-col">
                                <p className="text-lg font-medium"><strong>Email:</strong> {parent.email}</p>
                                <p className="text-lg font-medium"><strong>Phone Number:</strong> {parent.phone_number}</p>
                                <p className="text-lg font-medium"><strong>Address:</strong> {parent.address}</p>
                                {parent.opinion_goal && (
                                    <p className="text-lg font-medium"><strong>Opinion Goal:</strong> {parent.opinion_goal}</p>
                                )}
                                {parent.social_media_link && (
                                    <p className="text-lg font-medium"><strong>Social Media:</strong>
                                        <a
                                            href={parent.social_media_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {parent.social_media_link}
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <div className="mt-8">
                    <h2 className="text-2xl font-semibold mb-4">Membres liés à {parent.first_name}</h2>
                    {members.length === 0 ? (
                        <p>Aucun membre trouvé pour ce parent.</p>
                    ) : (
                        members.map(member => {
                            // Find status and type names
                             return (
                                <Card key={member.member_id} className="mb-4">
                                    <CardHeader>
                                        <CardTitle className="text-xl font-semibold">
                                            {`${member.first_name} ${member.last_name}`}
                                        </CardTitle>
                                        <a href={`/admin/members/${member.member_id}`} className="text-sm text-gray-600">
                                            Member Details
                                        </a>
                                    </CardHeader>
                                    <CardContent>
                                        <div className="flex gap-4 items-center">
                                            {member.pic_path_link ? (
                                                <img
                                                    className="h-24 w-24 rounded-full object-cover"
                                                    src={member.pic_path_link}
                                                    alt="member-image"
                                                />
                                            ) : (
                                                <img
                                                    className="h-24 w-24 rounded-full object-cover"
                                                     src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${member.first_name} ${member.last_name}`}
                                                    alt="member-image"
                                                />
                                            )}
                                            <div className="flex flex-col">
                                                <p className="text-lg font-medium"><strong>Birthdate:</strong> {member.birthdate}</p>
                                                <p className="text-lg font-medium"><strong>Establishment:</strong> {member.etablisment}</p>
                                                <p className="text-lg font-medium"><strong>Study Level:</strong> {member.study_level}</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            );
                        })
                    )}
                </div>
            </div>
        </Layout>
    );
}
