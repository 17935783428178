import React from 'react'
import TiltCard from '../../../shared_components/Card3D/Card3D'
import BubbleText from '../../../shared_components/BubbleText/BubbleText'
import { FiEye, FiGitCommit, FiHeart, FiUsers } from 'react-icons/fi'


 

export default function Section5() {
  return (
    <div className='py-40'>
     
    <div className="flex m-auto place-content-center w-[85%]  justify-evenly  px-4 py-12 text-slate-900 flex-wrap lg:gap-0 gap-4 ">
      <div className="lg:mt-40">
      <TiltCard icon=<FiUsers style={{transform: "translateZ(75px)"}} className="mx-auto text-5xl" /> title="+50" desc="عضو" />
      </div>
      <TiltCard icon=<FiGitCommit style={{transform: "translateZ(75px)"}} className="mx-auto text-5xl" /> title="+20" desc="شريك" />
      <div className="lg:mt-32">
      <TiltCard icon=<FiEye style={{transform: "translateZ(75px)"}} className="mx-auto text-5xl" /> title="+2000" desc="متابع" />
      </div>
      <TiltCard icon=<FiHeart style={{transform: "translateZ(75px)"}} className="mx-auto text-5xl" /> title="+80" desc="داعم" />
    </div>
    <BubbleText label={"❤ المخترع الذكي#"} />
 
   
    
  
    </div>
  )
}
