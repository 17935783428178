import { useEffect, useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Card, CardContent, CardHeader } from "../../../../components/ui/card";
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from "../../../../components/ui/select";
import Layout from "../../layout";
import PageTitle from "../../../../components/PageTitle";
import { useParams } from "react-router-dom";
import { Utils } from "../../../../lib/Custom-Utils";
import axios from "axios";
import { Answer, Club, ClubSelection, Group, Level, Member, Payment, PreferredStudyTimes, Question, QuestionAnswerMember, Session, Transaction } from "@/lib/types";



export default function MemberForm() {
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const [member, setMember] = useState<Member>({
        parent_id: 0,
        member_id: 0,
        gender: "",
        first_name: "",
        last_name: "",
        birthdate: "",
        etablisment: "",
        study_level: "",
        membership_status: {
            description: "",
            status_name: "", // Provide a default value
        },
        membership_type: {
            description: "",
            type_name: "", // Provide a default value
        },
        prefered_study_time_member: [], // Initialize as an empty array
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchMemberDetails = async () => {
            try {
                const response = await axios.get(`${Utils.getUrlApi()}members/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Bearer token to the request
                    }});
                setMember(response.data);
                if (member.prefered_study_time_member) {
                    member.prefered_study_time_member.forEach((memberTime) => {

                    })
                }
            } catch (err) {
                setError('Failed to fetch member details');
            } finally {
                setLoading(false);
            }
        };
        if (id) {
            fetchMemberDetails();
        } else {
            // Set default data when id is undefined
            setLoading(false);
        }
    }, [id]);

    const [parents, setParents] = useState([]);
    const [clubs, setClubs] = useState<Club[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Uncomment the following lines when your API is ready
                const response = await axios.get(Utils.getUrlApi() + 'parents', {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Bearer token to the request
                    }});
                setParents(response.data);
            } catch (err) {
                //
            }


            try {
                // Uncomment the following lines when your API is ready
                const response = await axios.get(Utils.getUrlApi() + 'utils/clubs-with-levels', {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Bearer token to the request
                    }});
                setClubs(response.data);
            } catch (err) {
                //
            }
        };

        fetchData();
    }, []);

    const [studyTimeEntry, setStudyTimeEntry] = useState<{ day: string; time: string }>({ day: "", time: "" });

    const [club_level_selection, setClubSelection] = useState<ClubSelection>({
        club: null,
        level: null,
    });

    // Dummy data for the list boxes
    const studyLevels = [
        "1er", "2eme", "3eme", "4eme", "5eme", "6eme", "7eme", "8eme", "9eme", "1er lycee"
    ];

    const preferredStudyPlaces = [
        "hay ons", "no5ba", "rue mahdia"
    ];

    // Adding study time
    const addPreferredStudyTime = () => {
        if (studyTimeEntry.day && studyTimeEntry.time) {
            setMember(prevState => {
                const updatedPreferredTimes = prevState.prefered_study_time_member ? [...prevState.prefered_study_time_member] : [];

                // Check if the day already exists
                const existingDayEntry = updatedPreferredTimes.find(entry => entry.prefered_study_day === studyTimeEntry.day);

                if (existingDayEntry) {
                    // If the day exists, replace the existing time
                    existingDayEntry.prefered_study_time = studyTimeEntry.time;
                } else {
                    // If the day doesn't exist, create a new entry
                    updatedPreferredTimes.push({ prefered_study_day: studyTimeEntry.day, prefered_study_time: studyTimeEntry.time });
                }

                return {
                    ...prevState,
                    prefered_study_time_member: updatedPreferredTimes,
                };
            });
            setStudyTimeEntry({ day: "", time: "" });
        }
    };

    // Removing preferred study time
    const removePreferredStudyTime = (day: string) => {
        setMember(prevState => ({
            ...prevState,
            prefered_study_time_member: prevState.prefered_study_time_member?.filter(entry => entry.prefered_study_day !== day) || []
        }));
    };
    // Adding club selection
    const addClubSelection = () => {
        
        if (club_level_selection.club && club_level_selection.level ) {
            console.log('new club added')
            if(member.club_level_selection){
                setMember({
                    ...member,
                    club_level_selection: [
                        ...member.club_level_selection,
                        club_level_selection
                    ],
                });
            }else{
                setMember({
                    ...member,
                    club_level_selection: [
                        club_level_selection
                    ],
                });
            }
            
            setClubSelection({ club: null, level: null });
        }
    };



    // Removing club selection
    const removeClubSelection = (index: number) => {
        setMember({
            ...member,
            club_level_selection: member.club_level_selection?.filter((_, i) => i !== index),
        });
    };

    // Handle form submission
    const handleMemberSubmit = async () => {
        if (!member.first_name || !member.last_name || !member.parent_id) {
            alert("Veuillez remplir les champs obligatoires : Prénom et Nom, Parent selection");
            return;
        }
        if (id !== undefined) {

            try {
                const response = await fetch(Utils.getUrlApi() + "members/" + id, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(member),
                });

                if (response.ok) {
                    const result = await response.json();
                    window.open('/admin/members', '_self');
                    // Reset form or handle as needed
                } else {
                    alert("Erreur lors de la création du membre.");
                }
            } catch (error) {
                console.error("Erreur :", error);
            }
        } else {
            try {
                const response = await fetch(Utils.getUrlApi() + "members", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(member),
                });

                if (response.ok) {
                    const result = await response.json();
                    window.open('/admin/members', '_self');
                    // Reset form or handle as needed
                } else {
                    alert("Erreur lors de la création du membre.");
                }
            } catch (error) {
                console.error("Erreur :", error);
            }
        }


    };
    const [selectedParent, setSelectedParent] = useState(member.parent_id);
    return (
        <Layout>
            <div className="flex flex-col gap-5 w-full">
                <PageTitle title="Creation d'un membre" />
                <Card className="mt-10 mx-auto max-w-lg">
                    <CardHeader>
                    </CardHeader>
                    <CardContent>
                        <div className="grid gap-4">
                            {/* Parent Related */}
                            <div className="grid gap-2">
                                <Label htmlFor="study-level">Parent: </Label>
                                <Select value={String(member.parent_id)} onValueChange={(value: string) => setMember({ ...member, parent_id: Number(value) })}>
                                    <SelectTrigger id="parent-select">
                                        <SelectValue placeholder="Sélectionner le parent" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {parents.map((parent: any) => (
                                            <SelectItem aria-selected={String(member.parent_id) === String(parent.parent_id)} key={parent.parent_id} value={String(parent.parent_id)}>
                                                {parent.first_name + " " + parent.last_name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            {/* First Name */}
                            <div className="grid gap-2">
                                <Label htmlFor="first-name">Prénom <span className="text-red-500">*</span></Label>
                                <Input
                                    id="first-name"
                                    value={member.first_name}
                                    onChange={(e) => setMember({ ...member, first_name: e.target.value })}
                                    placeholder="Entrez le prénom"
                                    required
                                />
                            </div>

                            {/* Last Name */}
                            <div className="grid gap-2">
                                <Label htmlFor="last-name">Nom <span className="text-red-500">*</span></Label>
                                <Input
                                    id="last-name"
                                    value={member.last_name}
                                    onChange={(e) => setMember({ ...member, last_name: e.target.value })}
                                    placeholder="Entrez le nom"
                                    required
                                />
                            </div>
                            {/* Last Name */}
                            <div className="grid gap-2">
                                <Label htmlFor="last-name">Date De Naissence <span className="text-red-500">*</span></Label>
                                <Input
                                    id="last-name"
                                    value={member.birthdate}
                                    onChange={(e) => setMember({ ...member, birthdate: e.target.value })}
                                     required
                                     type="date"
                                />
                            </div>
                            {/* Gender selection (Optional) */}
                            <div className="grid gap-2">
                                <Label htmlFor="gender">Genre</Label>
                                <Select value={member.gender} onValueChange={(value) => setMember({ ...member, gender: value })}>
                                    <SelectTrigger id="gender">
                                        <SelectValue placeholder="Sélectionner le genre" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem aria-selected={member.gender === "male"} value="male">Homme</SelectItem>
                                        <SelectItem aria-selected={member.gender === "female"} value="female">Femme</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            {/* Etablissement (Optional) */}
                            <div className="grid gap-2">
                                <Label htmlFor="etablisment">Etablissement</Label>
                                <Input
                                    id="etablisment"
                                    value={member.etablisment}
                                    onChange={(e) => setMember({ ...member, etablisment: e.target.value })}
                                    placeholder="Entrez l'établissement"
                                />
                            </div>

                            {/* Study Level (Optional) */}
                            <div className="grid gap-2">
                                <Label htmlFor="study-level">Niveau d'études</Label>
                                <Select value={member.study_level} onValueChange={(value: any) => setMember({ ...member, study_level: value })}>
                                    <SelectTrigger id="study-level">
                                        <SelectValue placeholder="Sélectionner le niveau d'études" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {studyLevels.map((level) => (
                                            <SelectItem aria-selected={member.study_level === level} key={level} value={level}>
                                                {level}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            {/* Preferred Study Place (Optional) */}
                            <div className="grid gap-2">
                                <Label htmlFor="preferred-study-place">Lieu d'étude préféré</Label>
                                <Select value={member.preferred_study_place}
                                    onValueChange={(value) =>
                                        setMember({ ...member, preferred_study_place: value })
                                    }
                                >
                                    <SelectTrigger id="preferred-study-place">
                                        <SelectValue placeholder="Sélectionner le lieu d'étude" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {preferredStudyPlaces.map((place) => (
                                            <SelectItem aria-selected={member.preferred_study_place === place} key={place} value={place}>
                                                {place}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            {/* Club selection (Optional) */}
                            <div className="grid gap-2">
                                <Label>Sélection du Club</Label>
                                <div className="flex space-x-2">
                                    <Select
                                        onValueChange={(value) =>
                                            setClubSelection({ ...club_level_selection, club: JSON.parse(value) })
                                        }
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Sélectionner le club" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {clubs.map((club: any) => (
                                                <SelectItem key={club.club_id} value={JSON.stringify(club)}>
                                                    {club.club_name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>

                                    <Select
                                        onValueChange={(value) =>
                                            setClubSelection({ ...club_level_selection, level: JSON.parse(value) })
                                        }
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Sélectionner le niveau" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {club_level_selection.club && club_level_selection.club.levels.map((level: Level) => (
                                                <SelectItem key={level.level_id} value={JSON.stringify(level)}>
                                                    {level.level_name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                <Button onClick={addClubSelection} className="mt-2">
                                    Ajouter Club
                                </Button>
                                {/* Display added clubs */}
                                {member.club_level_selection && member.club_level_selection.length > 0 && (
                                    <div className="mt-4">
                                        <Label>Clubs sélectionnés</Label>
                                        <ul>
                                            {member.club_level_selection.map((club, index) => (
                                                <li key={index} className="flex justify-between items-center">
                                                    {club.club?.club_name} - {club.level?.level_name}
                                                    <Button
                                                        className="ml-4"
                                                        onClick={() => removeClubSelection(index)}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {/* Preferred Study Time (Optional) */}
                            <div className="grid gap-2">
                                <Label>Temps d'étude préféré</Label>
                                <div className="flex space-x-2">
                                    <Select
                                        onValueChange={(value) =>
                                            setStudyTimeEntry({ ...studyTimeEntry, day: value })
                                        }
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Sélectionner le jour" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"].map((day) => (
                                                <SelectItem key={day} value={day}>
                                                    {day}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Input
                                        type="time"
                                        value={studyTimeEntry.time}
                                        onChange={(e) => setStudyTimeEntry({ ...studyTimeEntry, time: e.target.value })}
                                        placeholder="Heure préférée"
                                    />
                                </div>
                                <Button onClick={addPreferredStudyTime} className="mt-2">
                                    Ajouter Temps d'Étude
                                </Button>
                                {/* Display added preferred study times */}
                                {member.prefered_study_time_member && member.prefered_study_time_member.length > 0 && (
                                    <div className="mt-4">
                                        <Label>Temps d'étude préférés</Label>
                                        <ul>
                                            {member.prefered_study_time_member.map((entry, index) => (
                                                <li key={index} className="flex justify-between items-center">
                                                    {entry.prefered_study_day}: {entry.prefered_study_time}
                                                    <Button
                                                        className="ml-4"
                                                        onClick={() => removePreferredStudyTime(entry.prefered_study_day)}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <Button onClick={handleMemberSubmit} className="w-full mt-4">
                                Enregistrer
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </Layout>
    );
}
