import BarChart from "../../components/BarChart";
import PageTitle from "../../components/PageTitle";
import CustomCard, { CustomCardContent, CustomCardProps } from "../../components/CustomCard";
//import { Activity, CreditCard, DollarSign, Users } from "lucide-react";
import SalesCard, { SalesProps } from "../../components/SalesCard";
import Layout from './layout';
import {
  Activity,
  ArrowUpRight,
  CircleUser,
  CreditCard,
  DollarSign,
  Menu,
  Package2,
  Search,
  Users,
} from "lucide-react"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar"
import { Badge } from "../../components/ui/badge"
import { Button } from "../../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { Input } from "../../components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table"

const cardData: CustomCardProps[] = [
  {
    label: "إجمالي الإيرادات",
    amount: "45,231.89 دينار",
    discription: "+20.1% مقارنة بالشهر الماضي",
    icon: DollarSign
  },
  {
    label: "الاشتراكات",
    amount: "+2350",
    discription: "+180.1% مقارنة بالشهر الماضي",
    icon: Users
  },
  {
    label: "المبيعات",
    amount: "+12,234",
    discription: "+19% مقارنة بالشهر الماضي",
    icon: CreditCard
  },
  {
    label: "الأطفال النشطين",
    amount: "+573",
    discription: "+201 مقارنة بالشهر الماضي",
    icon: Activity
  }
]

const userSalesData: SalesProps[] = [
  {
    name: "أوليفيا مارتن",
    email: "olivia.martin@email.com",
    salesAmount: "+1,999.00 دينار"
  },
  {
    name: "جاكسون لي",
    email: "jackson.lee@email.com",
    salesAmount: "+1,999.00 دينار"
  },
  {
    name: "إيزابيلا نجوين",
    email: "isabella.nguyen@email.com",
    salesAmount: "+39.00 دينار"
  },
  {
    name: "ويليام كيم",
    email: "will@email.com",
    salesAmount: "+299.00 دينار"
  },
  {
    name: "صوفيا ديفيس",
    email: "sofia.davis@email.com",
    salesAmount: "+39.00 دينار"
  }
];

export default function AdminPanel() {
  return (
    <Layout>
      <div className="flex flex-col gap-5 w-full">
  <PageTitle title="لوحة التحكم" />
  <section className="grid w-full grid-cols-1 gap-4 gap-x-8 transition-all sm:grid-cols-2 xl:grid-cols-4">
    {cardData.map((data, index) => (
      <CustomCard
        key={index}
        amount={data.amount}
        discription={data.discription}
        icon={data.icon}
        label={data.label}
      />
    ))}
  </section>

  <section className="grid grid-cols-1 gap-4 transition-all lg:grid-cols-2">
    <CustomCardContent>
      <p className="p-4 font-semibold">نظرة عامة</p>
      <BarChart />
    </CustomCardContent>
    <CustomCardContent className="flex justify-between gap-4">
      <section>
        <p>الاشتراكات

الأخيرة</p>
        <p className="text-sm text-gray-400">
          لقد قمت ببيع 265 اشتراكًا هذا الشهر.
        </p>
      </section>
      {userSalesData.map((data, index) => (
        <SalesCard
          key={index}
          email={data.email}
          name={data.name}
          salesAmount={data.salesAmount}
        />
      ))}
    </CustomCardContent>
  </section>
</div>


    <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <Card x-chunk="dashboard-01-chunk-0">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                إجمالي العضويات
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">230 عضوًا</div>
              <p className="text-xs text-muted-foreground">
                +5% منذ الشهر الماضي
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-1">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                تسجيلات جديدة
              </CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">+30</div>
              <p className="text-xs text-muted-foreground">
                +10% منذ الشهر الماضي
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-2">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">الأنشطة</CardTitle>
              <Activity className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">15 نشاطًا</div>
              <p className="text-xs text-muted-foreground">
                +3 أنشطة جديدة
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-3">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">الأعضاء النشطون</CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">120 نشطون</div>
              <p className="text-xs text-muted-foreground">
                +10 منذ الأسبوع الماضي
              </p>
            </CardContent>
          </Card>
        </div>
        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <Card
            className="xl:col-span-2" x-chunk="dashboard-01-chunk-4"
          >
            <CardHeader className="flex flex-row items-center">
              <div className="grid gap-2">
                <CardTitle>الأنشطة الأخيرة</CardTitle>
                <CardDescription>
                  نظرة عامة على الأنشطة الأخيرة في النادي.
                </CardDescription>
              </div>
              <Button asChild size="sm" className="ml-auto gap-1">
                <a href="0">
                  عرض الكل
                  <ArrowUpRight className="h-4 w-4" />
                </a>
              </Button>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>العضو</TableHead>
                    <TableHead className="hidden xl:table-column">
                      النشاط
                    </TableHead>
                    <TableHead className="hidden xl:table-column">
                      التاريخ
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <Avatar className="h-6 w-6">
                          <AvatarImage src="https://via.placeholder.com/150" />
                          <AvatarFallback>AB</AvatarFallback>
                        </Avatar>
                        <span>أحمد بن صالح</span>
                      </div>
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      حضر نشاط الرسم
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      2024-08-10
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <Avatar className="h-6 w-6">
                          <AvatarImage src="https://via.placeholder.com/150" />
                          <AvatarFallback>MB</AvatarFallback>
                        </Avatar>
                        <span>محمد بن علي</span>
                      </div>
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      شارك في نشاط البرمجة
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      2024-08-09
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <Avatar className="h-6 w-6">
                          <AvatarImage src="https://via.placeholder.com/150" />
                          <AvatarFallback>RK</AvatarFallback>
                        </Avatar>
                        <span>ريما كمال</span>
                      </div>
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      حضر نشاط الموسيقى
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      2024-08-08
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <Avatar className="h-6 w-6">
                          <AvatarImage src="https://via.placeholder.com/150" />
                          <AvatarFallback>HN</AvatarFallback>
                        </Avatar>
                        <span>هالة ناصر</span>
                      </div>
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      شاركت في نشاط الرياضة
                    </TableCell>
                    <TableCell className="hidden xl:table-cell">
                      2024-08-07
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-5">
            <CardHeader>
              <CardTitle>الأنشطة القادمة</CardTitle>
              <CardDescription>
                جدول الأنشطة المخطط لها.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid gap-4">
                <div>
                  <div className="flex items-center justify-between">
                    <span>ورشة عمل البرمجة</span>
                    <Badge>2024-08-18</Badge>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    مستوى مبتدئ
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <span>دورة في الرسم</span>
                    <Badge>2024-08-20</Badge>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    مستوى متقدم
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <span>مهرجان الموسيقى</span>
                    <Badge>2024-08-22</Badge>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    لجميع الأعمار
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    </Layout>
  )
}
