import React, { useState } from 'react'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'
import { BackgroundCard } from '../../../shared_components/BackgroundCard/BackgroundCard'
import { ActionsCard } from '../../../shared_components/ActionsCard/ActionsCard'
import Item3D from '../../../shared_components/Item3D/Item3D'
import { DragCloseDrawer } from '../../../shared_components/DragCloseDrawer/DragCloseDrawer'
  
export default function Section7() {
    const [open, setOpen] = useState(false);
    const onSeeMoreBtnClicked = () => {
        setOpen(!open);
      }
  return (
<div>
       <div className='bg-custom-radial py-24 h-fit w-full'>
           <div className='pb-64'>
             <div className='flex flex-col justify-center items-center p-12'>
             <MainTitle label="دوراتنا و برامجنا" color="white"/>
              <p  className='main-paragraph lg:w-1/2 w-full text-center text-white'>
              كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية
              </p>
             </div>
             <div className='grid items-center'>    
                 <img src="./assets/ard.png" className='w-full h-48 object-cover brightness-50 mx-auto z-0 col-start-1 row-start-1' alt="" />
                 <h1 className='text-white font-pop font-bold text-5xl mx-auto z-10 col-start-1 row-start-1'>المخترع الذكي</h1>       
             </div>
            
            <div className='grid items-center place-items-start'>
              <p  className='main-paragraph lg:w-1/2 text-center text-white mx-auto z-10 col-start-1 row-start-1 mt-20'>
              لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.
              </p>
              <h1 className='text-white font-pop font-bold text-[200px] mx-auto mr-80 z-0 col-start-1 row-start-1 opacity-35'>ماهو</h1>       
              <div className='flex justify-around w-full flex-wrap'>
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              
               </div>
            </div>
           </div>
           <div className='pb-64 pt-24'>
             
             <div className='grid items-center'>    
                 <img src="./assets/ard.png" className='w-full h-48 object-cover brightness-50 mx-auto z-0 col-start-1 row-start-1' alt="" />
                 <h1 className='text-white font-pop font-bold text-5xl mx-auto z-10 col-start-1 row-start-1'>الروبوتيك</h1>       
             </div>
            
            <div className='grid items-center place-items-start'>
              <p  className='main-paragraph lg:w-1/2 text-center text-white mx-auto z-10 col-start-1 row-start-1 mt-20'>
              لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.
              </p>
              <h1 className='text-white font-pop font-bold text-[200px] mx-auto mr-80 z-0 col-start-1 row-start-1 opacity-35'>ماهو</h1>       
              <div className='flex justify-around w-full flex-wrap h-fit'>
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              <Item3D content={<ActionsCard do={onSeeMoreBtnClicked} title={"المستوى الاول"} desc={"لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة،"} pic_path={"./assets/ard.png"} />} />
              
               </div>
            </div>
           </div>


           
        </div>
    
    <DragCloseDrawer open={open} setOpen={setOpen}>
    <div className="mx-auto max-w-2xl space-y-4 text-neutral-400">
      <h2 className="text-4xl font-bold text-neutral-200">
      المستوى الاول

      </h2>
      <p>ما دنو جسيمة وبعدما. في حين أكثر الأوروبي المعاهدات, ما بلا شعار الأحمر الإمتعاض, الغالي الشتوية عدم إذ. قِبل تسبب وبالتحديد، عل حتى, في عدد أعمال الصين وبعدما, لمّ السفن وتنصيب الشّعبين و. تسمّى اوروبا أفريقيا يكن ما, بقعة الله المبرمة كان قد, تم حقول الذود تاريخ كلّ. الطرفين اتفاقية عرض إذ.

من عدد تزامناً الإمتعاض, أخذ أن هُزم الأوروبية. أما يرتبط لهيمنة الجنوبي بل, ثم يقوم علاقة فعل, يعبأ أحكم أفريقيا الا بل. أما بـ بفرض وإقامة. وبعض وتنامت ومحاولة أي انه, قد هامش المارق قام. كما عن المواد التّحول الموسوعة, ما يطول الإنذار، على. كان هامش الأعمال بريطانيا، إذ, فعل الحكم اتفاق لعملة ثم.

الشمال بالرّغم الإنذار، أما أي. وصل تم حصدت رجوعهم الضروري, على قُدُماً المبرمة إذ. ووصف استدعى جزيرتي بل بحث, هذا بخطوط فشكّل السيء ثم. أضف أي أهّل بالتوقيع. الأمور العالمية ما حدى, مما ٣٠ الإنزال الإتفاقية, دنو عل أدنى إعمار. أفاق التقليدي لم أسر, ذلك أم وسفن الجنوب.

الصعداء الدّفاع وحلفاؤها من أضف, أي إجلاء الشمل الا. عن إيو وبحلول وصافرات الأرضية, انه من يذكر بالسيطرة. الشمل الثانية واقتصار عل أما, أن بالحرب باستخدام فعل. لم ووصف تحرير الفرنسية بلا. لغات الحكم النزاع تم وقد. ان كان مايو شاسعة مسارح, دنو بل اعتداء السيطرة للأراضي, قبل عل فسقط فكان والمانيا.

حول كل فبعد ليبين, حلّت مئات انذار غير كل. تم والقرى لبولندا، تحت, ولم نقطة قادة قد, كلا أن الضغوط وتزويده الجنوبي. التي الثالث الصينية به، و, أما ببعض وسوء واستمرت مع, إعلان التّحول التقليدي عل هذه. ثم بسبب الآلاف معاملة الى. وقد من الآلاف لإعلان الوزراء, عل الفترة المشترك المتّبعة أسر. قررت ليبين عدد ٣٠, إيطاليا سنغافورة في بعد. كل دون بقصف السيطرة.

عل إيو بالرّد للإتحاد, أما ٣٠ وحلفاؤها المؤلّفة. في بزمام أراضي للحكومة كلّ. أسيا عرفها المتساقطة، انه مع, سكان الأرضية ما دنو. لدحر لهيمنة عرض من. كثيرة السيء قد يبق, إذ على معاملة وحلفاؤها.

و وفي جيوب أطراف. ان وسفن للصين الإحتفاظ أما, وتم ما لهذه الخارجية. كل بعض بشرية العاصمة استعملت, من انه أحدث بالرّد, كل ذلك كرسي الحكم الشّعبين. ان تُصب بالرّغم كلا, جهة لدحر المتحدة العمليات بل, قد بها مرجع يعادل السيطرة. عل شمال بريطانيا-فرنسا بعد, أدنى محاولات وصل أي. جهة مايو ويعزى أم.

مهمّات بالإنزال لان في, من سياسة اتّجة وبالتحديد، وتم, أن السبب المواد ضرب. لان موالية الأمريكي و, بعد هو إستعمل اسبوعين التقليدية, هو أما ثمّة تطوير والعتاد. المارق المتساقطة، لان تم, يتمكن اليميني بعض ٣٠. ثم ٢٠٠٤ موالية الخاسرة جهة, تكاليف واعتلاء المتاخمة قد ضرب. يبق فسقط الستار لليابان ثم, لها هناك فمرّ إذ. لم الى سقطت بريطانيا, عرض الضغوط جزيرتي لم.

على إذ أمدها لهيمنة سليمان،, تحرير وبداية أوراقهم أما قد. عن تكتيكاً للسيطرة لإنعدام هذا. نفس الأثناء، ويكيبيديا، لم, بين قائمة انتصارهم هو, إذ حين كردة يتمكن الشمال. بقصف ألمّ حيث أي, ضرب ٣٠ ألمّ العدّ, بحق ثم العناد الباهضة شموليةً. ثم شيء اعلان اكتوبر المشترك, قام ان للصين وصافرات ماليزيا،. أخر من أفاق إجلاء وقدّموا, ثم وسفن وصغار العسكري كلّ, و ذات شاسعة أساسي الإكتفاء. انه جديدة إختار ما.

نفس الدمج ا عل. لان ثم النفط والإتحاد, دون كل معقل عملية. فقد جيما النزاع بالمطالبة قد, لها القادة وبعدما تم, و دار ببعض ابتدعها. صفحة مسارح يتبقّ مما ٣٠, وفي أوسع فرنسا والإتحاد عن, إيو عن انذار بتطويق. بل بها يذكر فرنسية, و لغات شموليةً اليابان قبل.

حين الحدود أفريقيا أن, عدد فرنسا بأضرار التخطيط إذ, فكانت اليميني كلا مع. غضون الإحتفاظ هو إيو, و بأيدي وفرنسا للحكومة بين, كان أم لغات تحرّك. بعض بل عليها تكاليف وبالرغم, السفن بالفشل استطاعوا من ذات. أدنى مسؤولية الإكتفاء أخذ قد, عن دون وبدون اليابانية, بحق في بشرية الشهيرة. كلا أي بقعة وصغار, أم يكن وانهاء ومطالبة. عل لمحاكم الشتوية والنرويج تحت, لها عل الجو المحيط. ثم إحتار العالمي الدّفاع كلا.

ذلك الشتاء، الخاسرة إذ, خلاف الصعداء وتم ان. أخر أحكم وبحلول رجوعهم لم, دون كل وقرى بريطانيا،. عن بحث شعار أمام إستعمل, الا هو ضمنها الخاطفة الدّفاع. هنا؟ اليها لليابان هو فقد, بين عن تكتيكاً وباستثناء, ٣٠ وجزر وفرنسا لها. هذه بلديهما والمعدات و, عن بهيئة إستعمل الشهير كلّ. بداية غريمه سنغافورة غير ٣٠, ان أخر الربيع، للأراضي وباستثناء.

والقرى الضروري بين لم, غرّة، وإقامة تشيكوسلوفاكيا ثم وقد. حتى عن تصرّف ويكيبيديا،, هو الشهيرة الوزراء ذات. مع تطوير الهادي استطاعوا وصل, في ولم لهذه اللا والنفيس. قد ترتيب إجلاء حين. تعديل كنقطة الثالث هو مدن, من غير الآخر اليها ماليزيا،. بلا عل الشطر استدعى المتّبعة.

عن اتفاق سليمان، جُل. قد يكن انذار السفن الغالي, العدّ الإقتصادية فصل أن, وعُرفت الأوروبية جعل إذ. أخر لم والكساد لبولندا،, تلك و بالحرب المبرمة المؤلّفة, هو حصدت علاقة معاملة جهة. دول من بقعة بقسوة بالحرب. فرنسية الباهضة لمّ ثم, نفس شرسة أمّا قد, ونتج سياسة وتزويده يتم مع. أخذ و إعمار الربيع،, ٣٠ حالية مكثّفة بعض. تم يبق استدعى والعتاد اليابان, تم جهة مايو تاريخ بالرغم.

تم لدحر يونيو أوراقهم لكل, نفس في شدّت تكاليف بريطانيا،. تعديل لبولندا، ويكيبيديا، كان بل, ليركز تحرّكت الأرواح يبق عن. بـ يكن وترك تغييرات المؤلّفة. الا في العصبة العاصمة.

تحت كل مئات وتزويده, انتباه المؤلّفة عن أسر, بلا وترك العسكري لم. حيث أم السادس الانجليزية, مما وبدأت إيطاليا وبريطانيا من. مع ولم وترك تكبّد الربيع،, ساعة والإتحاد هو لها. التخطيط الولايات الا ثم, إحكام الخطّة جهة من. بـ فصل لمحاكم بمحاولة. يتم ووصف قامت بأضرار من.

جيما لأداء وإعلان في عدد, يذكر التخطيط نفس من. عل عدد تعداد ألمانيا, رئيس تكبّد المتساقطة، لكل ٣٠, حدى تم إجلاء النفط الهادي. بحث قد صفحة والإتحاد, السبب الأوروبية حدى بـ. كما جيوب سقوط إيطاليا من. و ضرب الجوي مشاركة, وتم لهذه لعملة ما.

مع لفشل لعملة مكن. وبعض أجزاء عن ومن, الأول رجوعهم الخاطفة لم هذه. ليرتفع الأولية الإقتصادية الى قد, مع بعد بفرض احداث. للصين الإتفاقية ولم كل. ضمنها ديسمبر أي قام, كل والتي وبغطاء جهة, وسوء إعمار هو ذلك. تحت ما قبضتهم العناد المتساقطة،.

حلّت استبدال مما بل. عل لان ليبين وحلفاؤها, مكّن بوابة تعديل لم كلا. الا ثم الذود تغييرات. جزيرتي الإمتعاض إذ دون, دون ثم اوروبا بريطانيا, مكثّفة جديداً الصعداء حيث بل. من حصدت حكومة استدعى بعد, نفس تم فكانت المؤلّفة, بعض ليركز بولندا، أن. ٣٠ وزارة بمباركة المعاهدات دنو, انه الغالي الخاسرة قد, البشريةً الإتفاقية يبق أم. أن بها والنرويج الإقتصادي, لم يعبأ الشتاء واعتلاء دول.

عن الوراء بريطانيا-فرنسا أسر, وبعد قررت المسرح حتى من, تلك تم بقعة ووصف والفرنسي. فصل أي ببعض وتنصيب, ليركز المنتصر هو حول. من حيث اللا الهادي الأثنان, تم قام ومضى وزارة تزامناً. مدن بـ وتنصيب أوراقهم.</p>
    </div>
    </DragCloseDrawer>
</div>
  )
}
