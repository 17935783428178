import MainTitle from '../../../shared_components/MainTitle/MainTitle'
import Item from './Section6_comp/item'
 

export default function Section6() {
  return (
    <div className='page-layout gap-4'>
       <MainTitle label="نسعى دائما للتميز <<<"/>

        <p  className='main-paragraph lg:w-1/2 text-center'>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية
        </p>
      <div className='flex flex-wrap flex-row-reverse justify-around items-center gap-20 mt-12'>
      <Item  num="1" time="1"  title="الذي ربما" desc="لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار"/>
      <Item  num="2" time="1.3"  title="الذي ربما" desc="لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار"/>
      <Item  num="3"  time="1.6" title="الذي ربما" desc="لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار"/>
      <Item  num="4"  time="1.9" title="الذي ربما" desc="لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار"/>

      </div>
 
    </div>
  )
}
