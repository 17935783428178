import { createBrowserRouter } from 'react-router-dom';
import { Register } from './pages/auth/register';
import Login from './pages/auth/login';
import Home from './pages/home/home';
import ProtectedRoute from './ProtectedRoute';
 
import AdminPanel from './pages/admin/page';
import MembersPage from './pages/admin/modules/members/Members';
import MemberForm from './pages/admin/modules/members/MemberForm';
import MemberById from './pages/admin/modules/members/by_id/MemberById';
import ParentsPage from './pages/admin/modules/parents/ParentsPage';
import { ParentForm } from './pages/admin/modules/parents/ParentForm';
import ParentById from './pages/admin/modules/parents/by-id/ParentById';
import AdminLogin from './pages/admin/auth/AdminLogin';



// Function to get the access token from cookies
const getAccessToken = () => {
  //   return Cookies.get('accessToken');
  return localStorage.getItem('token');
}

// Function to check if the user is authenticated
const isAuthenticated = () => {
   return !!getAccessToken();
}

// Create the router configuration
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Home />,
      index: true
    },
    {
      path: '/login',
      element: <Login />,
      index: true
    },
    {
      path: '/register',
      element: <Register />,
      index: true
    },
    
    {
      path: 'admin/systemlog',
      element: <AdminLogin />,
      index: true
    },
    {
      path: "/admin",
      element: <ProtectedRoute isAuthenticated={isAuthenticated()} />,
      children: [
        {
          path: 'panel',
          element: <AdminPanel />
        },
        {
          path: 'parents',
          children: [
            {
              index: true,
              element: <ParentsPage />
            },
            {
              path: 'create',
              element: <ParentForm />
            },
            {
              path: 'edit/:id',
              element: <ParentForm />
            },
            {
              path: ':id',
              element: <ParentById />
            },
          ],
        },
        {
          path: 'members',
          children: [
            {
              index: true,
              element: <MembersPage />
            },
            {
              path: 'create',
              element: <MemberForm />
            },
            {
              path: 'edit/:id',
              element: <MemberForm />
            },
            {
              path: ':id',
              element: <MemberById />
            },
          ],
        },
      ]
    },
    {
      path: '*',
      element: <p>404 Error - Nothing here...</p>
    }
  ]
);

export default router;