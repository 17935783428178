 import { Button, Input, Typography } from "@material-tailwind/react";
import { MyNavBar } from "../../../shared_components/navBar/MyNavBar";
import ParticleRing from "../../../shared_components/ParticleRing/ParticleRing";


export default function Hero(){

    return(
        <>
   

        <div className="h-screen overflow-hidden"  style={{background : 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(./assets/bg.png?v=123456})' }}>
          
           
            
            <ParticleRing />
        </div>
        </>
    )
}