import React from 'react'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'

export default function Section1() {
  return (
    <div className='page-layout gap-4'>
       <MainTitle label="تعلم اخر الاصدارات و التقنيات"/>

        <p  className='main-paragraph w-1/2 text-center'>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية
        </p>
        <div className='flex'>
          <img src={"./assets/scratch.png"} alt="Logo" className='w-40 h-auto object-cover' />
          <img src={"./assets/py.png"} alt="Logo" className='w-40 h-auto object-cover' />
          <img src={"./assets/js.png"} alt="Logo" className='w-40 h-auto object-cover' />
          
        </div>
        </div>
  )
}
