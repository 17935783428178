import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Dodecahedron, Icosahedron, OrbitControls, Plane, Sphere, TorusKnot } from "@react-three/drei";
import { pointsInner, pointsOuter } from "./utils";
import { Button } from "@material-tailwind/react";
import { MyNavBar } from "../navBar/MyNavBar";

const ParticleRing = () => {
  return (
    <div className="relative grid justify-items-center  justify-between items-center w-screen">
      <Canvas
        camera={{
          position: [10, -7.5, -5],
        }}
        style={{ height: "100vh", width: "100vw" }}
        className="bg-slate-900 blur-sm mx-auto z-0 col-start-1 row-start-1 w-screen" 
      >
        <OrbitControls maxDistance={20} minDistance={10} />
        <directionalLight />
        <pointLight position={[-30, 0, -30]} power={10.0} />
        <PointCircle />
      </Canvas>
      <div className="flex absolute justify-start flex-col top-0 w-full items-start mx-auto z-10 col-start-1 row-start-1 h-fit">
      <MyNavBar />

      </div>
      <div className="flex justify-center flex-col items-center mt-24 mx-auto z-10 col-start-1 row-start-1 h-fit">
                <h1 className="text-center text-7xl font-pop font-bold text-white "  >انظم الى نادينا الان
                و ابدا في عالم المغامرة و التعلم</h1>
                <h1 className="text-center text-2xl font-pop font-light text-white  mt-5">  كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية</h1> 
           
              <div className="mt-4 gap-4 flex">
              <Button  size="lg" className="hidden lg:inline-block text-white bg-C1 text-2xl">
                <span>اشتراك الان</span>
              </Button>
              <Button variant="text" size="lg" className="hidden lg:inline-block bg-white text-black text-2xl">
                <span>تعرف المزيد على دوراتنا</span>
              </Button>
              </div>
            </div>
     


    </div>
  );
};

const PointCircle = () => {
  const ref = useRef(null);

  useFrame(({ clock }) => {
    if (ref.current?.rotation) {
      ref.current.rotation.z = clock.getElapsedTime() * .05;
    }
  });

  return (
    <group ref={ref}>
      {pointsInner.map((point) => (
        <Point key={point.idx} position={point.position} color={point.color} />
      ))}
      {pointsOuter.map((point) => (
        <Point key={point.idx} position={point.position} color={point.color} />
      ))}
    </group>
  );
};

const Point = ({ position, color }) => {
  return (
    <Sphere position={position} args={[0.1, 10, 10]}>
      <meshStandardMaterial
        emissive={color}
        emissiveIntensity={0.5}
        roughness={0.5}
        color={color}
      />
    </Sphere>
  );
};
export default ParticleRing;