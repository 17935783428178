import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import {AiFillFacebook,AiFillInstagram,AiFillYoutube} from "react-icons/ai"





function Footer() {
    return (

        <footer className="flex w-full flex-col  flex-wrap items-center justify-center bg-[#252525] p-12">

            <h1 className="font-monta font-bold text-5xl text-white text-center">المخترع الذكي</h1>
            <div className="my-4">
                <h3 className="flex items-center justify-center gap-2 font-pop text-lg text-white"><HiOutlineMail /> <span>contact@intelligentinventor.com</span></h3>
                <h3 className="flex items-center justify-center gap-2 font-pop text-lg text-white"><HiOutlinePhone /> (00216) 00 800 375</h3>
            </div>
            <div className="flex justify-start flex-col">
                <div className="flex gap-2 items-center">
                    <a href="https://www.facebook.com/TastyGabes" className="text-3xl font-normal font-monta  text-white">  <AiFillFacebook /> </a>
                    <a href="https://www.instagram.com/tasty_rolled_ice_cream" className="text-3xl font-normal font-monta   text-white"> <AiFillInstagram /> </a>
                    <a href="https://test.com" className="text-3xl font-normal font-monta   text-white"> <AiFillYoutube /> </a>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
