import { useEffect, useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../components/ui/card";
import PageTitle from "../../../../components/PageTitle";
import Layout from "../../layout";
import { useParams } from "react-router-dom";
import { Utils } from "../../../../lib/Custom-Utils";

// Parent registration component
export function ParentForm({ onNext }: any) {
    const { id } = useParams(); // Get the ID from the URL params
    const token = localStorage.getItem('token');

    const [parentData, setParentData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: ""
    });

    const [loading, setLoading] = useState(false); // Loading state for fetching parent data

    // Fetch parent data when the component mounts and ID exists
    useEffect(() => {
        if (id !== undefined) {
            setLoading(true); // Set loading to true while fetching data
            fetch(`${Utils.getUrlApi()}parents/${id}`, {
                method: 'GET', // Ensure the method is GET (default for fetch, but included for clarity)
                headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${token}` 
                }
              })
                .then(response => response.json())
                .then(data => {
                  setParentData(data); // Populate the form with the fetched parent data
                  setLoading(false); // Set loading to false after the data is loaded
                })
                .catch(error => {
                  console.error("Error fetching parent data:", error);
                  setLoading(false); // Set loading to false in case of error
                });
              
    }}, [id]);

    // Validation and submission for parent creation
    const handleParentSubmit = async () => {
        // Basic validation
        if (!parentData.phone_number ) {
            alert("Please fill in Phone Number field.");
            return;
        }
        // Perform API call to create parent
        if(id!==undefined){
            try {
                const response = await fetch(Utils.getUrlApi()+"parents/"+id , {
                  method: "PUT",
                  headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}` 
                  },
                  body: JSON.stringify(parentData),
                });
                
                if (response.ok) {
                  const result = await response.json();
                  window.open('/admin/parents', '_self');

                } else {
                  alert("Error creating parent.");
                }
              } catch (error) {
                console.error("Error:", error);
              }
        }
        else{
            try {
                const response = await fetch(Utils.getUrlApi()+"parents", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` 
                  },
                  body: JSON.stringify(parentData),
                });
                
                if (response.ok) {
                  const result = await response.json();
                  window.open('/admin/parents', '_self');
              //    alert("Parent created successfully!");
                  
                } else {
                  alert("Error creating parent.");
                }
              } catch (error) {
                console.error("Error:", error);
              }
        }
        
    };

    if (loading) {
        return <div>Loading parent data...</div>; // Show a loading state while fetching
    }
    return (
        <Layout>
        <div className="flex flex-col gap-5 w-full">
        <PageTitle title="Creation d'un parent" />
            <Card className="mt-10 mx-auto max-w-md">
                <CardHeader>
                    <CardTitle></CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="grid gap-2">
                                <Label htmlFor="first-name">Nom</Label>
                                <Input
                                    id="first-name"
                                    value={parentData.first_name}
                                    onChange={(e) => setParentData({ ...parentData, first_name: e.target.value })}
                                    placeholder="Ahmed"
                                    
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="last-name">Prenom</Label>
                                <Input
                                    id="last-name"
                                    value={parentData.last_name}
                                    onChange={(e) => setParentData({ ...parentData, last_name: e.target.value })}
                                    placeholder="Ali"
                                    
                                />
                            </div>
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="email">Email*</Label>
                            <Input
                                id="email"
                                value={parentData.email}
                                onChange={(e) => setParentData({ ...parentData, email: e.target.value })}
                                type="email"
                                placeholder="m@example.com"
                                
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="phone_number">Numero Mobile</Label>
                            <Input
                                id="phone_number"
                                value={parentData.phone_number}
                                onChange={(e) => setParentData({ ...parentData, phone_number: e.target.value })}
                                placeholder="79345678"
                                
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="address">Address</Label>
                            <Input
                                id="address"
                                value={parentData.address}
                                onChange={(e) => setParentData({ ...parentData, address: e.target.value })}
                                placeholder="rue la XIO"
                                
                            />
                        </div>
                        <Button onClick={handleParentSubmit} className="w-full">
                            Enregistrer
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
        </Layout>
    );
}

