import React from 'react'
import SquishyCard from '../../../shared_components/SquishyCard/SquishyCard'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'

export default function Section8() {
  return (
    <div className='py-24'>
    <div className='page-layout gap-4 pb-4 mb-1'>
    <MainTitle label="الاشتراكات" />
    <p className='main-paragraph lg:w-1/2 text-center'>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية
    </p>
    </div>
      <div className='flex justify-evenly flex-wrap gap-4'>
      <SquishyCard />
      <SquishyCard />
      <SquishyCard />
      </div>
    </div>
  )
}
