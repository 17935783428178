import {AiFillFacebook,AiFillInstagram,AiFillYoutube} from "react-icons/ai"


export default function InfoBar(){

    return (
        <>
         <div className="w-full h-fit py-1 text-white bg-C2 flex justify-around items-center ">
            <h1>اتصل بنا: +216 99 99 99 99</h1>
            <div className="flex justify-around items-center">
                <AiFillFacebook className="text-3xl"/>
                <AiFillInstagram  className="text-3xl"/>
                <AiFillYoutube  className="text-3xl"/>
            </div>

        </div>
        </>
    )
}
