import React, { useEffect, useState } from 'react'
import { Carousel, Typography, Button } from "@material-tailwind/react";

export default function Slider(props) {
    const [data, setData]= useState([])
    useEffect(()=>{
        setData(props.data);
    }, []);
  return (
    <div>

     <Carousel className="rounded-xl w-full lg:h-auto h-96">
        {data.map((d)=>(
           <div className="relative h-full w-full">
           <img
             src={d.pic_path} alt={d.seo}
             className="h-full w-full object-cover"
           />
           <div className="absolute  bottom-0 grid h-full w-full place-items-end bg-black/45">
             <div className="lg:w-[85%] w-full text-center  flex justify-between items-center mx-auto" >
               
               <div className="flex justify-center gap-2">
                 <Button size="lg" color="white">
                 اكتشف المزيد
                 </Button>
                 <Button size="lg" color="white" variant="text" hidden>
                   Gallery
                 </Button>
               </div>
  
               <div>
                  <Typography
                    variant="h1"
                    color="white"
                    className="mb-4 text-xl md:text-2xl lg:text-2xl"
                  >
                   {d.title}
                  </Typography>
                  <Typography
                    variant="lead"
                    color="white"
                    className="mb-12 opacity-80"
                  >
                   {d.desc}
                  </Typography>
               </div>
             </div>
           </div>
         </div>
        ))}
      
     </Carousel>
   
    </div>
  )
}
