/**
 * eslint-disable @next/next/no-img-element
 *
 * @format
 */

/** @format */
"use client";

import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Layout from "../../../layout";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/ui/card";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import PageTitle from "../../../../../components/PageTitle";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import { Answer, Club, Group, Member, Payment, Question, QuestionAnswerMember, Session, Transaction } from "../../../../../lib/types";




// Define columns for DataTable
const sessionColumns: ColumnDef<Session>[] = [
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "date",
    header: "Date",
  },
  {
    accessorKey: "start_time",
    header: "Start Time",
  },
  {
    accessorKey: "end_time",
    header: "End Time",
  },
  {
    accessorKey: "Member_Attendance.member_attendance_status.status_name",
    header: "Member Attendance",
    cell: ({ row }) => row.original.Member_Attendance?.member_attendance_status?.status_name || '-', // Handle nested property

  },
];

const transactionColumns: ColumnDef<Transaction>[] = [
  {
    accessorKey: "transaction_date",
    header: "Transaction Date",
    cell: ({ getValue }) => getValue(), // Format date as needed
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ getValue }) => `${getValue()} TND`, // Append currency suffix
  },
  {
    accessorKey: "payment_type.type_name",
    header: "Transaction Type",
    cell: ({ row }) => row.original.payment_type?.type_name || 'Unknown', // Handle nested property
  },
];

const sampleGroups: Group[] = [
  { group_id: 1, name: "Math Club", max_members_number: 20 },
  { group_id: 2, name: "Science Club", max_members_number: 15 },
  // More sample groups...
];

const sampleSessions: Session[] = [
  { session_id: 1, date: "2024-10-01", start_time: "09:00", end_time: "10:00", description: "Math Session", Member_Attendance: { member_attendance_id: 0, member_id: 0, session_id: 0, member_attendance_status: { member_attendance_status_id: 0, status_name: "Present" } } },
  { session_id: 2, date: "2024-10-02", start_time: "10:00", end_time: "11:00", description: "Science Session", Member_Attendance: { member_attendance_id: 0, member_id: 0, session_id: 0, member_attendance_status: { member_attendance_status_id: 0, status_name: "Absent" } } },
  { session_id: 3, date: "2024-10-03", start_time: "11:00", end_time: "12:00", description: "History Session" },
  { session_id: 4, date: "2024-10-04", start_time: "12:00", end_time: "01:00", description: "Geography Session" },
  { session_id: 5, date: "2024-10-05", start_time: "01:00", end_time: "02:00", description: "Art Session" },
  // More sample sessions...
];

const samplePayments: Payment[] = [
  { payment_id: 1, registration_fees: 100, paid_amount: 50, rest_to_pay: 50, payment_status_id: 1, trimester_id: 1 },
  // More sample payments...
];

const sampleTransactions: Transaction[] = [
  { transaction_id: 1, payment_id: 1, amount: 50, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "12345", transaction_date: "2024-09-01" },
  { transaction_id: 2, payment_id: 1, amount: 30, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "67890", transaction_date: "2024-09-15" },
  { transaction_id: 3, payment_id: 1, amount: 20, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "11223", transaction_date: "2024-09-20" },
  { transaction_id: 4, payment_id: 1, amount: 40, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "44556", transaction_date: "2024-09-25" },
  { transaction_id: 5, payment_id: 1, amount: 10, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "78901", transaction_date: "2024-09-30" },
  { transaction_id: 1, payment_id: 1, amount: 50, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "12345", transaction_date: "2024-09-01" },
  { transaction_id: 2, payment_id: 1, amount: 30, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "67890", transaction_date: "2024-09-15" },
  { transaction_id: 3, payment_id: 1, amount: 20, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "11223", transaction_date: "2024-09-20" },
  { transaction_id: 4, payment_id: 1, amount: 40, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "44556", transaction_date: "2024-09-25" },
  { transaction_id: 5, payment_id: 1, amount: 10, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "78901", transaction_date: "2024-09-30" },
  { transaction_id: 1, payment_id: 1, amount: 50, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "12345", transaction_date: "2024-09-01" },
  { transaction_id: 2, payment_id: 1, amount: 30, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "67890", transaction_date: "2024-09-15" },
  { transaction_id: 3, payment_id: 1, amount: 20, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "11223", transaction_date: "2024-09-20" },
  { transaction_id: 4, payment_id: 1, amount: 40, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "44556", transaction_date: "2024-09-25" },
  { transaction_id: 5, payment_id: 1, amount: 10, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "78901", transaction_date: "2024-09-30" },
  { transaction_id: 1, payment_id: 1, amount: 50, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "12345", transaction_date: "2024-09-01" },
  { transaction_id: 2, payment_id: 1, amount: 30, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "67890", transaction_date: "2024-09-15" },
  { transaction_id: 3, payment_id: 1, amount: 20, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "11223", transaction_date: "2024-09-20" },
  { transaction_id: 4, payment_id: 1, amount: 40, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "44556", transaction_date: "2024-09-25" },
  { transaction_id: 5, payment_id: 1, amount: 9999, payment_type: { payment_type_id: 1, type_name: "Cash", description: "" }, num_transfer: "78901", transaction_date: "2099-09-30" },

  // More sample transactions...
];

const sampleQuestions: Question[] = [
  { question_id: 1, label: "What is your favorite subject?" },
  // More sample questions...
];

const sampleAnswers: Answer[] = [
  { answers_id: 1, label: "Math" },
  { answers_id: 2, label: "Science" },
  // More sample answers...
];

const sampleQuestionAnswers: QuestionAnswerMember[] = [
  { question_answer_member_id: 1, question_id: 1, answer_id: 1, member_id: 1 },
  // More sample question answers...
];


export default function MemberById() {
  const [member, setMember] = useState<Member | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [clubs, setClubs] = useState<Club[]>([]);
  const [sessionPage, setSessionPage] = useState<number>(1);
  const [transactionPage, setTransactionPage] = useState<number>(1);
  const token = localStorage.getItem('token');

  // Using URLSearchParams to get the ID from query params
  const { id } = useParams();
  const handleDelete = async() =>{
    const PIN = prompt("PIN :");
    if(PIN==="3002"){
      await axios.delete(Utils.getUrlApi()+'members/'+id, {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }});
      window.open('/admin/members', '_self');
    }
  }
  const itemsPerPage = 2;
  useEffect(() => {
    const fetchData = async () => {
    /*  try {
        // Uncomment the following lines when your API is ready
         const response = await axios.get(Utils.getUrlApi()+'parents');
         setParents(response.data);
      } catch (err) {
        //
        }
*/

        try {
            // Uncomment the following lines when your API is ready
             const response = await axios.get(Utils.getUrlApi()+'utils/clubs-with-levels', {
              headers: {
                  'Authorization': `Bearer ${token}` // Add Bearer token to the request
              }});
             setClubs(response.data);
          } catch (err) {
            //
            }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        // Commented out API call for member details
        const response = await axios.get(`${Utils.getUrlApi()}members/${id}`, {
          headers: {
              'Authorization': `Bearer ${token}` // Add Bearer token to the request
          }});
        setMember(response.data);        
        // Simulate fetching member details data
        /* const foundMember = sampleMemberData.find(m => m.member_id === Number(id));
         if (foundMember) {
           setMember(foundMember);
         } else {
           setError('Member not found');
         }*/
      } catch (err) {
        setError('Failed to fetch member details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMemberDetails();
    }
  }, [id]);

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;
  if (!member) return <p className="text-center">Member not found</p>;

  return (
    <Layout>
      <PageTitle title="Member Details" />
      <div className="flex flex-col gap-5 p-5 w-full max-w-4xl mx-auto">
        <Card>
          <CardHeader className="flex justify-between">
            <div>
              <CardTitle className="text-xl font-semibold">
                {`${member.first_name} ${member.last_name}`}
              </CardTitle>
              <h3 className="text-lg text-gray-600">
                Member Details
              </h3>
            </div>
            <div>
              <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/members/edit/' + member.member_id, '_self') }}>
                Modifier
              </button>
              <button onClick={()=>{handleDelete()}} className="bg-deep-orange-800 text-white px-2 py-1" >
                Supprimer
              </button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex gap-4 items-center">
              {member.pic_path_link ? (
                <img
                  className="h-24 w-24 rounded-full object-cover"
                   src={member.pic_path_link}
                 // src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${member.first_name} ${member.last_name}`}
                  alt="member-image"
                />
              ) : (
                <img
                className="h-24 w-24 rounded-full object-cover"
                 src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${member.first_name} ${member.last_name}`}
                alt="member-image"
              />
              )}
              <div className="flex flex-col">
                <p className="text-lg font-medium"><strong>Date de naissance:</strong> {member.birthdate}</p>
                <p className="text-lg font-medium"><strong>Établissement:</strong> {member.etablisment}</p>
                <p className="text-lg font-medium"><strong>Niveau d'étude:</strong> {member.study_level}</p>
                <p className="text-lg font-medium"><strong>Lieu d'étude préféré:</strong> {member.preferred_study_place}</p>
                <p className="text-lg font-medium"><strong>Sélection du club:</strong> {(member.club_level_selection && member.club_level_selection.length > 0)
                  ? member.club_level_selection.map((clubSelection) => (
                    clubSelection.club?.club_name + " - " + clubSelection.level?.level_name
                  )).join(', ')  // Joins the mapped elements into a single string
                  : "-"
                }</p>
                <p className="text-lg font-medium"><strong>Statut de membre:</strong> {member.membership_status.status_name}</p>
                <p className="text-lg font-medium"><strong>Type d'adhésion:</strong> {member.membership_type.type_name}</p>
                <div className="mt-4">
                  <h3 className="text-lg font-medium">Horaires d'étude préférés</h3>
                  <ul className=" list-inside ml-5">
 

                    {member.prefered_study_time_member?.map(studyTime => (
                      <li key={studyTime.prefered_study_time_member_id}>
                        <p><strong>Jour:</strong> {studyTime.prefered_study_day}</p>
                        <p><strong>Temp:</strong> {studyTime.prefered_study_time}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </CardContent>
        </Card>

        {/* Display Group Memberships */}
        {member.groups && member.groups.length > 0 && (
          <Card className="mt-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Groups</CardTitle>
            </CardHeader>
            <CardContent>
              <ul>
                {member.groups.map(group => (
                  <li key={group.group_id}>
                    <p className="text-lg font-medium">{group.name}</p>
                    <p className="text-sm text-gray-600">Max Members: {group.max_members_number}</p>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}

        {/* Display Sessions */}
        {member.sessions && member.sessions.length > 0 && (
          <Card className="my-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Sessions</CardTitle>
            </CardHeader>
            <CardContent>
              <DataTable
                columns={sessionColumns}
                data={member.sessions}
              />
            </CardContent>
          </Card>
        )}

        {/* Display Payments */}
        {member.payments && member.payments.length > 0 && (
          <Card className="mt-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Payments</CardTitle>
            </CardHeader>
            <CardContent>
              <ul>
                {member.payments.map(payment => (
                  <li key={payment.payment_id}>
                    <p className="text-lg font-medium">Registration Fees: ${payment.registration_fees}</p>
                    <p className="text-sm text-gray-600">Paid Amount: ${payment.paid_amount}</p>
                    <p className="text-sm text-gray-600">Rest to Pay: ${payment.rest_to_pay}</p>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}

        {/* Display Transactions */}
        {member.transactions && member.transactions.length > 0 && (
          <Card className="my-4">
            <CardHeader>
              <CardTitle>Transactions</CardTitle>
            </CardHeader>
            <CardContent>
              <DataTable
                columns={transactionColumns}
                data={member.transactions}
              />
            </CardContent>
          </Card>
        )}
        {/* Display Question Answers */}
        {member.question_answers && member.question_answers.length > 0 && (
          <Card className="mt-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Question Answers</CardTitle>
            </CardHeader>
            <CardContent>
              <ul>
                {member.question_answers.map(qa => {
                  const question = sampleQuestions.find(q => q.question_id === qa.question_id);
                  const answer = sampleAnswers.find(a => a.answers_id === qa.answer_id);

                  return (
                    <li key={qa.question_answer_member_id}>
                      <p className="text-lg font-medium">Question: {question?.label}</p>
                      <p className="text-sm text-gray-600">Answer: {answer?.label}</p>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        )}
      </div>
    </Layout>
  );
}
