import React from 'react'

export default function MainTitle(prop) {
    let color;
    let color_svg;
    if(prop.color !== "white"){
        color = "C4";
        color_svg = "#2A3342";
      }else{
        color = "white";
        color_svg = "#fff";
    }
  
  return (
    <div>
     <h1 className={`main-title text-${color}`}>{prop.label}</h1>
          <svg width="160" height="16" viewBox="0 0 160 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 6.1473C26.6227 4.9208 51.5462 5.21159 76.2219 4.53221C99.1231 3.90169 122.002 3.05261 144.892 2.29374C148.107 2.18716 151.321 2.05215 154.539 2.01039C160.182 1.93721 158.291 2.24971 153.402 2.97378C147.3 3.87745 126.736 6.44867 122.685 6.99735C116.694 7.80862 110.679 8.56545 104.755 9.5475C100.782 10.206 90.2968 11.6079 93.7421 13.0044C97.6827 14.6015 106.558 13.7977 111.08 13.7977C115.403 13.7977 119.727 13.7977 124.05 13.7977" stroke={color_svg} stroke-width="3" stroke-linecap="round"/>
          </svg> 
    </div>
  )
}
