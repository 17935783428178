import React from 'react'
import MainTitle from '../../../shared_components/MainTitle/MainTitle'
import Slider2 from '../../../shared_components/Slider2/Slider2'
import { DragCards } from '../../../shared_components/DragCards/DragCards';
import { motion } from "framer-motion";

export default function Section4() {
    const cards = [
        {
          url: "./assets/ard.png",
          title: "Title 1",
          id: 1,
        },
        {
          url: "./assets/ard.png",
          title: "Title 2",
          id: 2,
        },
        {
          url: "./assets/ard.png",
          title: "Title 3",
          id: 3,
        },
        {
            url: "./assets/ard.png",
            title: "Title 3",
            id: 3,
          },
        
          {
            url: "./assets/ard.png",
            title: "Title 3",
            id: 3,
          },
        
          {
            url: "./assets/ard.png",
            title: "Title 3",
            id: 3,
          },
          
          {
            url: "./assets/ard.png",
            title: "Title 3",
            id: 3,
          },
          

      ];
    return (
        <div  className='section4 '>
          <svg className='w-full h-auto rotate-180 -mt-2' viewBox="0 0 1919 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1920 111.364H1453.44C1111.12 111.364 773.639 0 480 0C186.361 0 0 111.364 0 111.364V200H1920V111.364Z" fill="#F7F8F9"/>
        </svg>
        <motion.radialGradient
            id="gradient1"
            fr={0.2}
            fx={0.32}
            fy={0.32}
            r={0.7}
            animate={{ fr: 0.05, fx: 0.2, fy: 0.35, r: 0.6 }}
            transition={{
                repeat: Infinity,
                repeatType: "mirror",
                ease: "easeInOut",
                duration: 4
            }}
        >
            <stop offset="0%" stopColor="#ff0" />
            <stop offset="100%" stopColor="#c50" />
        </motion.radialGradient>
                   
      
                    <div className='page-layout pb-0 mb-0'>
                <MainTitle label="لماذا نحن ؟" color='white' />
                <p className='main-paragraph  text-center text-white mt-8'>
                    لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.
                </p>
            </div>
            
                <DragCards />
             
             
         
            </div>

     
    )
}
