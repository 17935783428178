import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import router from './Router';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RouterProvider router={router} />
  /*<React.StrictMode>
    <BrowserRouter>
        <Routes>
                <Route  path='/' element={<Home />} />
                <Route  path='/login' element={<Login />} />
                <Route  path='/register' element={<Register />} />
                <Route  path='/admin' element={<AdHome />} />
                <Route  path='/admin/parents' element={<Parents />} />
                <Route  path='/admin/parents/create' element={<ParentForm />} />
                <Route  path='/admin/parents/edit/:id' element={<ParentForm />} />
                <Route  path='/admin/parents/:id' element={<ParentById />} />
                <Route  path='/admin/members' element={<MembersPage />} />
                <Route  path='/admin/members/:id' element={<MemberById />} />
                <Route  path='/admin/members/create' element={<MemberForm />} />
                <Route  path='/admin/members/edit/:id' element={<MemberForm />} />
         </Routes>
    </BrowserRouter>
  </React.StrictMode>*/
);
