 
 
import "./globals.css";
import { cn } from "../../lib/utils";
import Sidebar from "../../components/ui/sidebar";
import { CustomSidebar } from "./components/custom_sidebar";

 


const Layout = ({ children }: { children?: React.ReactNode }) => {


  return (

    <html lang="en">
    <body
      className={cn(
        "min-h-screen w-full bg-white text-black flex justify-between",
         { "debug-screens": process.env.NODE_ENV === "development" }
      )}
    >
      {/* Sidebar
      <div className="w-[15%]"><Sidebar /></div>
      
      */}
      <CustomSidebar/>
      {/* main page */}
      <div className="p-8 w-full">{children}</div>
    </body>
  </html>
  );
}

export default Layout;